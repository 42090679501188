<dpa-type-ahead
  [selectedItem]="selectedIdentifier"
  [searchableItems]="identifiers ?? []"
  [isSuggestionLoading]="isLoadingIdentifiers$ | async"
  [keyBy]="getIdentifiersKey"
  [labelFormatter]="getIdentifiersKey"
  [customFormatter]="getIdentifiersKey"
  [placeholderText]="'COMMON_ACTIONS.SELECT_IDENTIFIER' | translate"
  (selectedItemChange)="onIdentifierChange($event)"
  (onQueryChange)="onIdentifierQueryChange($event)"
/>
